import React, { useCallback, type ReactNode, type ReactElement } from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
// eslint-disable-next-line jira/restricted/@atlaskit+page-header
import PageHeader from '@atlaskit/page-header';
import { useActionsCommandPalette } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

export type HeaderProps = {
	title: string;
	actions?: ReactElement;
	children?: ReactNode;
	isAdminSettingsContext?: boolean;
};

export const Header = ({ title, actions, children, isAdminSettingsContext }: HeaderProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const searchActionLabel = formatMessage(messages.searchJiraAdmin);

	const { open } = useActionsCommandPalette();

	const onClick = useCallback(() => {
		open();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'searchActionButton',
		});
		fireUIAnalytics(analyticsEvent, 'dotDialog');
	}, [createAnalyticsEvent, open]);

	const shifterAction = (
		<ButtonGroup>
			{actions}
			{isAdminSettingsContext && (
				<Button iconBefore={<SearchIcon label="" size="small" />} onClick={onClick}>
					{searchActionLabel}
				</Button>
			)}
		</ButtonGroup>
	);
	return (
		<>
			<PageHeader actions={shifterAction}>{title}</PageHeader>
			{children}
		</>
	);
};
